// https://github.com/alexandresanlim/Badges4-README.md-Profile#how-to-use
// https://github.com/alexandresanlim/Badges4-README.md-Profile#how-to-use

import * as React from "react"
import Link from "gatsby-link"
import Helmet from "react-helmet"
import ArticleList from "../components/ArticleList"
import SideBar from "../components/SideBar"
import * as config from "../constants"
import { graphql } from 'gatsby'
import { TemplateWrapper } from "../layouts"
import PageTitle from "../components/PageTitle"
import { ReactJs, Csharp } from '@icons-pack/react-simple-icons';


export const ExperiencePage = (props) => {
  const coverImage = `${config.DOMAIN}/img/twitter-default.png`
  const pageTitle = "Experience | " + config.SITE_TITLE;
  return (
    <TemplateWrapper>
      <div className="my-10 lg:mx-6 w-full flex-grow">
        <PageTitle>Experience</PageTitle>
        <p>These are some of the fun projects I've worked with others on over the years. If you're looking for my resume, you can find that on my <a href={`config.LINKEDIN`}>LinkedIn profile</a>.</p>
        <h2>Online Donation Software</h2>
        <p>Details go here.. </p>
        <h3>Tools</h3>
        <ReactJs size={24} className="inline mr-3" />
        {/* <Csharp size={24} title="C#" /> */}
        <Csharp size={24} className="inline mr-3" />
        



        <hr className="opacity-50 my-6" />
        <p>Tool icons provided by <a href="https://simpleicons.org/" className="link">Simple Icons</a>.</p>
      </div>

      <Helmet
        title={`${config.SITE_TITLE}`}
        meta={[
          { name: "description", content: config.SITE_DESCRIPTION },
          { property: "og:site_name", content: config.SITE_TITLE },
          { property: "og:type", content: "website" },
          { property: "og:title", content: pageTitle },
          { property: "og:description", content: config.SITE_DESCRIPTION },
          { property: "og:url", content: config.DOMAIN },
          { property: "og:image", content: coverImage },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:title", content: pageTitle },
          { name: "twitter:description", content: config.SITE_DESCRIPTION },
          { name: "twitter:url", content: config.DOMAIN },
          { name: "twitter:site", content: "@" + config.TWITTER },
          { name: "twitter:image", content: `${config.DOMAIN}/img/twitter-default.png` },
        ]}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Person",
            "name": pageTitle,
            "url": config.DOMAIN,
            "logo": `${config.DOMAIN}/img/profile-1.gif`,
            "sameAs": [
              "https://twitter.com" + config.TWITTER,
              "https://www.linkedin.com/in/" + config.LINKEDIN,
              "https://medium.com/@" + config.MEDIUM
              // "https://plus.google.com/+mattferderer",
              // "https://www.pinterest.com/mattferderer"
            ]
          })}
        </script>
      </Helmet>
    </TemplateWrapper>
  )
}

export default ExperiencePage